import * as client_hooks from '../../../src/hooks.client.ts';

export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21'),
	() => import('./nodes/22'),
	() => import('./nodes/23'),
	() => import('./nodes/24'),
	() => import('./nodes/25'),
	() => import('./nodes/26'),
	() => import('./nodes/27'),
	() => import('./nodes/28'),
	() => import('./nodes/29'),
	() => import('./nodes/30'),
	() => import('./nodes/31'),
	() => import('./nodes/32'),
	() => import('./nodes/33'),
	() => import('./nodes/34'),
	() => import('./nodes/35'),
	() => import('./nodes/36'),
	() => import('./nodes/37'),
	() => import('./nodes/38'),
	() => import('./nodes/39'),
	() => import('./nodes/40'),
	() => import('./nodes/41'),
	() => import('./nodes/42'),
	() => import('./nodes/43'),
	() => import('./nodes/44'),
	() => import('./nodes/45'),
	() => import('./nodes/46'),
	() => import('./nodes/47'),
	() => import('./nodes/48'),
	() => import('./nodes/49'),
	() => import('./nodes/50'),
	() => import('./nodes/51'),
	() => import('./nodes/52'),
	() => import('./nodes/53'),
	() => import('./nodes/54'),
	() => import('./nodes/55'),
	() => import('./nodes/56'),
	() => import('./nodes/57'),
	() => import('./nodes/58'),
	() => import('./nodes/59'),
	() => import('./nodes/60'),
	() => import('./nodes/61'),
	() => import('./nodes/62'),
	() => import('./nodes/63'),
	() => import('./nodes/64'),
	() => import('./nodes/65'),
	() => import('./nodes/66'),
	() => import('./nodes/67'),
	() => import('./nodes/68')
];

export const server_loads = [0,2,3,5];

export const dictionary = {
		"/": [~6],
		"/about": [7],
		"/about/licensing/publishers": [8],
		"/about/licensing/storytellers": [9],
		"/about/privacy-policy": [10],
		"/about/terms-of-service": [11],
		"/account": [12,[2]],
		"/account/billing": [~13,[2]],
		"/account/downloads": [~14,[2]],
		"/account/earnings": [15,[2]],
		"/account/general": [~16,[2]],
		"/account/invoice/[id]": [~17,[2]],
		"/account/licensing-history": [~18,[2]],
		"/account/notifications": [~19,[2]],
		"/account/orders": [~20,[2]],
		"/account/subscription": [21,[2]],
		"/admin": [22,[3]],
		"/admin/backups": [~23,[3]],
		"/admin/credits": [~24,[3]],
		"/admin/downloads": [~25,[3]],
		"/admin/migrate": [~26,[3]],
		"/admin/orders": [~27,[3]],
		"/admin/themes": [~28,[3]],
		"/admin/types": [~29,[3]],
		"/admin/users": [~30,[3]],
		"/admin/users/[id]": [~31,[3]],
		"/admin/users/[id]/edit": [~32,[3]],
		"/admin/utils": [33,[3]],
		"/admin/utils/clear": [~34,[3]],
		"/author/[username]": [~35],
		"/bookmarks": [~36],
		"/contact": [37],
		"/download/[slug]": [~38],
		"/editor-board": [~39],
		"/how-it-works": [40],
		"/how-it-works/publisher": [41],
		"/how-it-works/storyteller": [42],
		"/members": [~43],
		"/order/[id]/download": [~44,[],[4]],
		"/order/[id]/organizational-details": [~45,[],[4]],
		"/order/[id]/plan-active": [46,[],[4]],
		"/order/[id]/pricing": [~47,[],[4]],
		"/order/[id]/publication-details": [~48,[],[4]],
		"/our-story": [49],
		"/people": [~50],
		"/plain-html-story/[slug]": [~51],
		"/pricing": [52],
		"/profile": [~53],
		"/profile/edit": [~55],
		"/profile/[username]": [~54],
		"/search": [56,[5]],
		"/search/[query]": [57,[5]],
		"/sign-in": [58],
		"/sign-up": [59],
		"/sign-up/error": [60],
		"/sign-up/setup-account": [~61],
		"/sign-up/verify-email": [62],
		"/stories": [~63],
		"/story-backup/[slug]": [~67],
		"/story-manager": [~68],
		"/story/create": [~66],
		"/story/[slug]": [~65],
		"/story/[id]/edit": [~64]
	};

export const hooks = {
	handleError: client_hooks.handleError || (({ error }) => { console.error(error) }),
};

export { default as root } from '../root.svelte';